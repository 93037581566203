<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div v-if="community" id="bluemoon-lease-editor-container"></div>
    <div v-if="!community" class="mt-3 border border-blue-400 font-400 text-sm text-blue-800 font-frank px-4 py-6 bg-blue-200 text-center">
      <p>please select a community to continue.</p>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'leaseEditor',
  mixins: [NotifyMixin],
  components: { Loader },
  data() {
    return {
      isLoaded: false,
      loading: false,
      token: '',
      account: {
        id: '',
        username: 'quextUser@TX05101252',
        password: 'Madera1234',
        client_id: 4,
        client_secret: 'XgNRCbNfllV5inyfxS0zB1vbhhsG1FSAXwQptDbN'
      },
      document_instance_created: false,
      is_allow_to_save: false
    };
  },
  computed: {
    ...mapGetters({
      getDocumentInstance: 'leases/getLease',
      customerId: 'auth/customerId',
      community: 'auth/community'
    })
  },
  methods: {
    ...mapActions({
      setDocumentInstance: 'leases/setLease'
    }),
    getAuthBlueMoonToken() {
      this.loading = true;
      this.$blueMoonServiceDataProvider
        .create('authTokenBM', {
            // REPLACE THIS WITH REAL DATA
            data: {
                username: this.account.username,
                password: this.account.password,
                scope: 'full',
                grant_type: 'password',
                client_id: this.account.client_id,
                client_secret: this.account.client_secret,
            },
        })
        .then(async (res) => {
            this.token = res.access_token;
            await this.getProperty(res.access_token);
        })
        .catch(() => {
            this.loading = false;
            this.notifyError('something went wrong. please try again.');
        });
    },
    getProperty(access_token) {
      this.loading = true;
      this.$blueMoonServiceDataProvider
        .getList('property', {
          headers: {
            Authorization: `Bearer ${access_token}`
          },
      })
      .then(async (res) => {
          this.account.id = res.data[0].account_id;
          await this.launchEmbeddedUI(access_token, res.data[0].id);
      })
      .catch(() => {
          this.loading = false;
          this.notifyError('something went wrong. please try again.');
      });
    },
    launchEmbeddedUI(token, property_id) {
      const params = {
        view: 'create',
        leaseData: this.getLease ? { ...this.getLease } : {},
        lockPopulatedFields: false,
        useAutoSave: true
      };
      // eslint-disable-next-line no-undef
      const bluemoonElements = new BluemoonElements(token, property_id);
      const leaseEditorElement = bluemoonElements.load('bluemoon-lease-editor-container', 'bm-lease-editor', params);


      leaseEditorElement.addEventListener('saveEvent', (e) => {
        const leaseData = e.detail;
        if (this.is_allow_to_save) {
            if (this.document_instance_created) this.updateLease(leaseData);
            else this.saveLease(leaseData);
          }
        
        if (!e.detail.success) {
          this.notifyError("couldn't save the lease. please check for errors");
        }
      });

      leaseEditorElement.addEventListener('leaseDirtyEvent', (e) => {
        if (e.detail.dirty) this.is_allow_to_save = true;
      });

      leaseEditorElement.addEventListener('sectionSelectedEvent', (e) => {
        if (leaseEditorElement && e.detail.name === "terms") {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      });
    },
    saveLease(document_instance) {
      this.$leasingDataProvider
        .create('document_package_instance', {
          data: {
            business_unit_id: this.community.id,
            customer_id: this.customerId,
            partner_id: 'bdef439a-909e-4a28-a516-4aba4a669f85',
            document_type_name: 'Lease',
            document_initiated_by_app: 'Blue Moon',
            payload: document_instance,
          },
      })
      .then((res) => {
          this.setDocumentInstance({
            ...document_instance,
            document_package_instance_id: res.data.document_package_instance_id,
          });
          this.document_instance_created = true;
          this.is_allow_to_save = false;
      })
      .catch(() => {
          this.loading = false;
          this.notifyError('something went wrong. please try again.');
      });
    },
    updateLease(document_instance) {
      this.$leasingDataProvider
        .patch('document_package_instance', {
          document_package_instance_id: this.getDocumentInstance.document_package_instance_id,
          business_unit_id: this.community.id,
          customer_id: this.customerId,
          data: {
              partner_id: 'bdef439a-909e-4a28-a516-4aba4a669f85',
              document_type_name: 'Lease',
              document_initiated_by_app: 'Blue Moon',
              payload: document_instance,
          },
      })
      .then(() => {
        this.setDocumentInstance(document_instance);
        this.is_allow_to_save = false;
      })
      .catch(() => {
          this.loading = false;
          this.notifyError('something went wrong. please try again.');
      });
    }
  },
  mounted: function () {
    if (!this.community) this.notifyError('please select a community to continue, then refresh the browser');
    else this.$nextTick(this.getAuthBlueMoonToken);
  },
  unmounted() {
    this.document_instance_created = false;
  }
}
</script>